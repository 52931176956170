/* global dataLayer */
import React, { useState } from 'react';
import styled from 'styled-components';
import { IconButton, Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const StyledBanner = styled.div`
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100vw;
    padding-top: 40px;
    padding-bottom: 20px;
    display: flex;
    flex-direction: column;
    gap:10px;
    justify-content: space-between;
    align-items: center;
    color: #fff;
    transition: transform 0.3s ease;
    transform: ${({ show }) => (show ? 'translateY(0)' : 'translateY(100%)')};
    z-index: 9999999999;
    background: rgba(0, 0, 0, 0.37);
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(6.8px);
    -webkit-backdrop-filter: blur(6.8px);

`;

const CloseDiv = styled.div`
    position: relative;
    right: 0;
    margin-right: 25px;
    display: flex;
    gap: 10px;
  
    @media screen and (max-width: 12in) {
       margin:0;
    }
`;

const StyledMuiButton = styled(Button)`
    color: #fff !important;
    border-color: rgb(0 0 0) !important;
    background: rgb(0 0 0) !important;
    font-weight: bolder !important;
    font-family: "Montserrat", "Oxygen" !important;
`;

const CookieBanner = ({ show }) => {
    const [showBanner, setShowBanner] = useState(show);

    const handleClose = () => {
        setShowBanner(false);
    };

    const handleAccept = () => {
        localStorage.setItem('cookiesAccepted', 'all');
        const script = document.createElement('script');
        script.async = true;
        script.src = "https://www.googletagmanager.com/gtag/js?id=G-PJ7HHJZ4SG";
        document.head.append(script);
        window.dataLayer = window.dataLayer || [];
        function gtag() { dataLayer.push(arguments); }
        gtag('js', new Date());

        gtag('config', 'G-PJ7HHJZ4SG');
        handleClose();
    };

    const handleRefuse = () => {
        sessionStorage.setItem('cookiesRefused', 'true');
        handleClose();
    }

    return (
        <StyledBanner show={showBanner}>
            <IconButton name='close-cookies' style={{ position: 'fixed', right: 0, top: 0 }} onClick={handleClose}>
                <CloseIcon style={{ color: 'white' }} />
            </IconButton>
            <div style={{ marginLeft: '20px' }}>
                Il presente sito web utilizza cookie tecnici, strettamente necessari per il suo corretto funzionamento e, previo tuo consenso,
                cookie per migliorare le prestazioni del sito web e la tua esperienza di navigazione.
                Questi cookie possono essere di prima parte (cioè gestiti direttamente da TRL Express S.R.L.) o di terze parti.
                Puoi scegliere se accettare tutti i cookie cliccando sul pulsante “Accetta tutti i cookie”
                Puoi inoltre procedere con la navigazione in assenza di cookie o altri strumenti di tracciamento diversi da quelli tecnici, attraverso la chiusura del presente banner, cliccando sul pulsante “Rifiuta tutti”. Per maggiori informazioni puoi consultare la nostra Cookie policy
            </div>
            <div>
                <CloseDiv>
                    <StyledMuiButton name='accept-cookies' variant='outlined' size="medium" onClick={handleAccept}>
                        Accetta tutti i cookie
                    </StyledMuiButton>
                    <StyledMuiButton name='decline-cookies' variant='outlined' size="medium" onClick={handleRefuse}>
                        Rifiuta i cookie
                    </StyledMuiButton>
                </CloseDiv>
            </div>
        </StyledBanner>
    );
};

export default CookieBanner;
