import React, { useEffect, useState } from "react";
import Title from "../helpers/Title";
import TruckLoader from '../Loader/TruckLoader';
import { Link } from "react-router-dom";
import { Button } from "@mui/material";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTruckFast, faWarehouse, faTruckRampBox } from '@fortawesome/free-solid-svg-icons';
import './WhoWeAre.css';
import styled from "styled-components";
import trlImage1 from "../../images/trl_who_we_are/trl_who_we_are_1.webp";
import trlImage2 from "../../images/trl_who_we_are/trl_who_we_are_2.webp";
import trlImage3 from "../../images/trl_who_we_are/trl_express_who_we_are_3.webp";

const title = "Chi siamo";

const StyledBeforeContainer = styled.div`
background-color: #fff;
border-radius: 8px;
box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
margin-bottom: 2%;
`;

const StyledContainer = styled.div`
display: grid;
grid-template-columns: repeat(2, 1fr);
grid-template-rows: repeat(2, 1fr);

grid-column-gap: 10px;
grid-row-gap: 0px;
padding:10px;

&>div{
    height:48vh;
    padding:3%;
}
&>div>h5{
    font-size:1.1rem;
}

`;

const StyledMuiButton = styled(Button)`
color: #fff !important;
border-color: rgb(255 255 255) !important;
background: rgb(0 0 0) !important;
font-weight: bolder !important;
font-family: "Montserrat", "Oxygen" !important;
width: 40vw;
margin-top: 30px !important;
`;

const StyledWorkingHours = styled.div`
padding:1%;
h3 {
    margin-top:10px;
    color: #333;
    margin-bottom: 10px;
}

p {
    color: #666;
    margin-bottom: 8px;
}

ul {
    list-style: none;
    padding: 0;
    margin: 0;
}
`;

const trlImages = [
    trlImage1,
    trlImage2,
    trlImage3
];


export default function WhoWeAre() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        document.body.style.overflow = 'hidden';
        const handleLoad = () => {
            setLoading(false);
        };

        const iframe = document.querySelector('iframe');

        iframe.addEventListener('load', handleLoad);

        return () => {
            iframe.removeEventListener('load', handleLoad);
        };
    }, []);
    useEffect(() => {
        if (loading) {
            document.body.style.overflowX = 'hidden';
            document.body.style.overflowY = 'hidden';
        } else {
            document.body.style.overflowX = 'hidden';
            document.body.style.overflowY = 'auto';
        }
    }, [loading]);
    return (
        <>
            <Title title={title} />
            {loading && <TruckLoader text="Caricamento in corso..." />}
            <div className="who-we-are-container">
                <div className="page-header">
                    <h2>Chi siamo</h2>
                </div>
                <StyledBeforeContainer>
                    <StyledContainer className="wwa-container">
                        <div className="div1">
                            <h5>T.R.L. EXPRESS SRL</h5>
                            <p> Siamo un azienda di autotrasporti, presente in provincia di Milano, attiva da 15 anni su tutto il territorio nazionale ed europeo con servizi espressi dedicati.</p>
                            <p>Il nostro impegno costante è offrire ai nostri clienti un servizio impeccabile e rapido, prendendoci cura di ogni loro esigenza con la massima attenzione e dedizione..</p>
                            <p>Siamo in grado di garantire soluzioni di trasporto efficienti e su misura per le necessità specifiche di ciascun cliente, anche grazie ad una flotta di furgoni e mezzi centinati di varie portate, dotati sia di sponda idraulica che di altre attrezzature specializzate, per garantire una gestione ottimale dei carichi in qualsiasi situazione. </p>
                        </div>
                        <div className="image-container div2">
                            <img className={`top-image-wwa`} src={trlImages[0]} alt="Immagine auto carousel Home" />
                        </div>
                        <div className="image-container div4">
                            {/* <img className={`top-image-wwa`} src={trlImages[1]} alt="Immagine auto carousel Home" /> */}
                            <iframe
                                title='Dove Trovarci'
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2793.4996270205775!2d9.027803592873632!3d45.5603904383619!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x478694a73063a3ab%3A0xf5611b22186c8fc2!2sVia%20Pietro%20Mascagni%2C%2012%2C%2020045%20Lainate%20MI!5e0!3m2!1sit!2sit!4v1707149180528!5m2!1sit!2sit"
                                width="500%"
                                height="500px"
                                style={{ border: '0' }}
                                allowFullScreen
                                loading="eager"
                                referrerPolicy="no-referrer-when-downgrade"
                            ></iframe>
                        </div>
                        <div className="div3">
                            <h5 style={{ textAlign: "right" }}>DOVE E COME TROVARCI</h5>
                            <div className="who-we-are-div-4">
                                <p>Siamo in <strong><Link style={{ textDecoration: 'none', color: 'black' }} to="https://maps.app.goo.gl/RsvoT4u4h1zP7kXZ6" target='_blank' rel="noopener noreferrer">Via Pietro Mascagni 12, Lainate - 20045</Link></strong>,  vicino all'uscita dell'autostrada.</p>
                                <StyledWorkingHours className='working-hours'>
                                    <h3>Orari di Apertura</h3>
                                    <ul>
                                        <li>
                                            <strong>Lunedì:</strong> 8:00 - 18:00
                                        </li>
                                        <li>
                                            <strong>Martedì:</strong> 8:00 - 18:00
                                        </li>
                                        <li>
                                            <strong>Mercoledì:</strong> 8:00 - 18:00
                                        </li>
                                        <li>
                                            <strong>Giovedì:</strong> 8:00 - 18:00
                                        </li>
                                        <li>
                                            <strong>Venerdì:</strong> 8:00 - 18:00
                                        </li>
                                        <li>
                                            <strong>Sabato:</strong> Chiuso
                                        </li>
                                        <li>
                                            <strong>Domenica:</strong> Chiuso
                                        </li>
                                    </ul>
                                </StyledWorkingHours>
                                <Link className="contact-us-link-wwa" to="/contact-us"><StyledMuiButton name='contact-us-who-we-are' variant="outlined">Contattaci</StyledMuiButton></Link>
                            </div>
                        </div>
                    </StyledContainer>
                    <div id="services-container" style={{ padding: '10px' }}>
                        <h1>I nostri servizi</h1>
                        <div className="services">
                            <div className="service">
                                <FontAwesomeIcon icon={faTruckFast} size="2xl" style={{ color: '#161631' }} />
                                <h7>TRASPORTI DEDICATI NAZIONALI ED EUROPEI H24</h7>
                                <p>365 giorni l'anno, 7 giorni su 7, siamo a disposizione per organizzare, gestire ed effettuare trasporti e consegne urgenti. Ritiro e consegna al destinatario con tempistica programmata.</p>
                            </div>
                            <div className="service">
                                <FontAwesomeIcon icon={faWarehouse} size="2xl" style={{ color: '#161631' }} />
                                <h7>DEPOSITO MERCI</h7>
                                <p>Abbiamo a disposizione un magazzino di 500mq dotato di sistema di videosorveglianza dove è possibile stoccare le merci in totale sicurezza per brevi o lunghi periodi.</p>
                            </div>
                            <div className="service">
                                <FontAwesomeIcon icon={faTruckRampBox} size="2xl" style={{ color: '#161631' }} />
                                <h7>LOGISTICA DISTRIBUTIVA</h7>
                                <p>Oltre al deposito merci, offriamo servizio di distribuzione quotidiana in tutta la Lombardia.</p>
                            </div>
                        </div>
                    </div>
                </StyledBeforeContainer>
            </div>
        </>
    );
}