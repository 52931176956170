import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons';
import Fab from '@mui/material/Fab';

import styled from 'styled-components';

const StyledButtonUp = styled(Fab)`
  position: fixed !important;
  bottom: 11% !important;
  right: 20px !important;
  cursor: pointer !important;
  transition: opacity 0.3s ease !important;
  opacity: ${({ isVisible }) => (isVisible ? '1' : '0')} !important;
`;

export default function GoToTop() {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const toggleVisibility = () => {
            if (window.pageYOffset > 200 || window.scrollY > 200) {
                setIsVisible(true);
            } else {
                setIsVisible(false);
            }
        };

        window.addEventListener('scroll', toggleVisibility);

        return () => {
            window.removeEventListener('scroll', toggleVisibility);
        };
    }, []);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    return (
        <>
            <StyledButtonUp name='go-to-top' size="medium" isVisible={isVisible} onClick={scrollToTop}>
                <FontAwesomeIcon icon={faArrowUp} size="xl" style={{ color: '#161631' }} />
            </StyledButtonUp>
        </>
    );
};
