import React, { useEffect, useState } from 'react';
import Title from '../helpers/Title';
import TruckLoader from '../Loader/TruckLoader';
import './WhereWeAre.css';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Button } from "@mui/material";

const title = 'Dove siamo';

const StyledContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 10px;
    grid-row-gap: 0px;
    padding:10px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const StyledWorkingHours = styled.div`
    padding:20px;
    margin-bottom:20px;
    position:relative;
    top:0;
    display: grid;
    justify-content: center;

    h3 {
    color: #333;
    margin-bottom: 10px;
    }

    p {
    color: #666;
    margin-bottom: 8px;
    }

    ul {
    list-style: none;
    padding: 0;
    margin: 0;
    }

    li {
    margin-bottom: 4px;
    }
`;

export default function WhereWeAre() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        document.body.style.overflow = 'hidden';
        const handleLoad = () => {
            setLoading(false);
        };

        const iframe = document.querySelector('iframe');

        iframe.addEventListener('load', handleLoad);

        return () => {
            iframe.removeEventListener('load', handleLoad);
        };
    }, []);

    useEffect(() => {
        if (loading) {
            document.body.style.overflowX = 'hidden';
            document.body.style.overflowY = 'hidden';
        } else {
            document.body.style.overflowX = 'hidden';
            document.body.style.overflowY = 'auto';
        }
    }, [loading]);

    return (
        <>
            <Title title={title} />
            {loading && <TruckLoader text="Caricamento della mappa in corso..." />}
            <div style={{ "overflow": "hidden", }} id='content'>
                <div className='page-header'>
                    <h2>Dove trovarci</h2>
                </div>
                <StyledContainer className='page-container'>
                    <div className='side-expl'>
                        <StyledWorkingHours className='working-hours'>
                            <h3>Orari di Apertura</h3>
                            <ul>
                                <li>
                                    <strong>Lunedì:</strong> 8:00 - 18:00
                                </li>
                                <li>
                                    <strong>Martedì:</strong> 8:00 - 18:00
                                </li>
                                <li>
                                    <strong>Mercoledì:</strong> 8:00 - 18:00
                                </li>
                                <li>
                                    <strong>Giovedì:</strong> 8:00 - 18:00
                                </li>
                                <li>
                                    <strong>Venerdì:</strong> 8:00 - 18:00
                                </li>
                                <li>
                                    <strong>Sabato:</strong> Chiuso
                                </li>
                                <li>
                                    <strong>Domenica:</strong> Chiuso
                                </li>
                            </ul>
                        </StyledWorkingHours>
                        <StyledWorkingHours>
                            <p>Vieni a trovarci in <Link to="https://maps.app.goo.gl/RsvoT4u4h1zP7kXZ6" target='_blank' rel="noopener noreferrer">Via Pietro Mascagni 12, Lainate</Link></p>
                            <p>Per ulteriori informazioni non esisitate a contattarci dall'apposita sezione</p>
                            <Link className="contact-us-link" to="/contact-us"><Button name='contact-us-where-we-are' style={{ color: '#fff', borderColor: '#fff', background: 'rgb(0 0 0/ 80%)', fontWeight: 'bolder', fontFamily: '"Montserrat", "Oxygen"' }} variant="outlined">Contattaci</Button></Link>
                        </StyledWorkingHours>
                    </div>
                    <div className='map'>
                        <iframe
                            title='Dove Trovarci'
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2793.4996270205775!2d9.027803592873632!3d45.5603904383619!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x478694a73063a3ab%3A0xf5611b22186c8fc2!2sVia%20Pietro%20Mascagni%2C%2012%2C%2020045%20Lainate%20MI!5e0!3m2!1sit!2sit!4v1707149180528!5m2!1sit!2sit"
                            width="100%"
                            height="600"
                            style={{ border: '0' }}
                            allowFullScreen
                            loading="eager"
                            referrerPolicy="no-referrer-when-downgrade"
                        ></iframe>
                    </div>
                </StyledContainer>
            </div>

        </>
    );
}
