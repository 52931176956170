/* global dataLayer */
import { useEffect } from 'react';
import { HashRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Navbar from './components/Navbar/Navbar';
import Footer from './components/Footer/Footer';

//COMPONTENTI DA RENDERIZZARE PER LE PATH
import Index from './components';
import WhoWeAre from './components/WhoWeAre/WhoWeAre';
import WhereWeAre from './components/WhereWeAre/WhereWeAre';
import ContactUs from './components/ContactUs/ContactUs';
import NotFound from './components/helpers/NotFound';
import Privacy from './components/InformativaPrivacy/InformativaPrivacy';
import GoToTop from './components/helpers/GoToTop';
import CookieBanner from './components/helpers/CookieBanner';

function App() {
  useEffect(() => {
    const cleanupLocalStorage = () => {
      const cookiesAcceptedDate = localStorage.getItem('cookiesAcceptedDate');

      if (cookiesAcceptedDate) {
        const oneMonthInMillis = 30 * 24 * 60 * 60 * 1000;
        const savedDate = new Date(cookiesAcceptedDate).getTime();
        const currentDate = new Date().getTime();

        if (currentDate - savedDate >= oneMonthInMillis) {
          localStorage.removeItem('cookiesAccepted');
          localStorage.removeItem('cookiesAcceptedDate');
        }
      }
    };

    cleanupLocalStorage();
  }, []);

  let countTimes = 0;

  useEffect(() => {
    const checkAnalytics = () => {
      if (localStorage.getItem("cookiesAccepted") === "all") {
        const script = document.createElement("script");
        script.async = true;
        script.src = "https://www.googletagmanager.com/gtag/js?id=G-PJ7HHJZ4SG";
        document.head.appendChild(script);

        window.dataLayer = window.dataLayer || [];
        function gtag() { dataLayer.push(arguments); }
        gtag('js', new Date());

        gtag('config', 'G-PJ7HHJZ4SG');
      }
    }

    while (!countTimes) {
      ++countTimes;
      checkAnalytics();
    }

  }, []);


  return (
    <Router>
      <AppRoutes />
    </Router>
  );
}

// Componente funzionale AppRoutes per gestire le route
function AppRoutes() {
  const location = useLocation();

  const isNotFoundRoute = location.pathname !== '/' &&
    location.pathname !== '/who-we-are' &&
    location.pathname !== '/where-we-are' &&
    location.pathname !== '/contact-us' &&
    location.pathname !== '/privacy-info';

  return (
    <>
      {!isNotFoundRoute && <Navbar />}
      <Routes>
        <Route path="/" element={<Index />} />
        <Route path="/who-we-are" element={<WhoWeAre />} />
        <Route path="/where-we-are" element={<WhereWeAre />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/privacy-info" element={<Privacy />} />
        {isNotFoundRoute && <Route path="*" element={<NotFound />} />}
      </Routes>
      {!isNotFoundRoute && <Footer />}
      {!isNotFoundRoute && <GoToTop />}
      {!isNotFoundRoute && <CookieBanner show={localStorage.getItem('cookiesAccepted') || sessionStorage.getItem('cookiesRefused') ? false : true} />}
    </>
  );
}

export default App;
