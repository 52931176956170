import React from 'react';
import styled from 'styled-components';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';
import Title from './Title';

const SD = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const NotFound = () => {
  return (
    <>
    <Title title="404"/>
    <SD>
      <h1>404 - Pagina non trovata</h1>
      <p>La pagina che stai cercando non esiste.</p>
      <Link to={"/"}><Button style={{ color: '#fff', borderColor: '#fff', background: 'rgb(0 0 0/ 80%)', fontWeight: 'bolder', fontFamily: '"Montserrat", "Oxygen"' }} variant='outlined'>Torna alla home</Button></Link>
    </SD>
    </>
  );
}

export default NotFound;