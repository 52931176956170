import React, { useState, useEffect } from "react";
import Alert from '@mui/material/Alert';
import Slide from '@mui/material/Slide';
import Snackbar from '@mui/material/Snackbar';
import TruckLoader from '../Loader/TruckLoader';
import emailjs from 'emailjs-com';
import styled from "styled-components";
import "./ContactUs.css";
import Button from '@mui/material/Button';
import Title from "../helpers/Title";
const title = "Contattaci";

const ContactFormContainer = styled.div`
max-width: 66%;
margin: auto;
padding: 40px;
background-color: #fff;
border-radius: 8px;
box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
display:flex;
flex-direction: column;
`;

const Form = styled.form`
display: grid;
grid-template-columns: repeat(2, 1fr);
grid-column-gap: 10px;
grid-gap: 20px;
padding: 4%;
margin-bottom: 2%;

label {
font-weight: bold;
margin-bottom: 8px;
}

input,
textarea {
    font-family: "Montserrat", "Oxygen",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
width: 90%;
padding: 10px;
border: 1px solid #ccc;
border-radius: 4px;
margin: auto;
resize: vertical;
}
`;


const StyledMuiButton = styled(Button)`
color: #fff !important;
border-color: rgb(255 255 255) !important;
background: rgb(0 0 0) !important;
font-weight: bolder !important;
font-family: "Montserrat", "Oxygen" !important;
width:100%;
`;


export default function ContactUs() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const [name, setName] = useState();
    const [surname, setSurname] = useState();
    const [number, setNumber] = useState();
    const [mail, setMail] = useState();
    const [hasCompany, setHasCompany] = useState(false);
    const [company, setCompany] = useState();
    const [message, setMessage] = useState();

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValidEmail = (email) => {
        return emailRegex.test(email);
    };
    const handleSubmit = async () => {
        setIsUploading(true);

        if (!name || !surname || !mail) {
            showAlert('error', 'Nome, cognome e email sono obbligatori!');
            setIsUploading(false);
            return false;
        }

        if (isValidEmail(mail)) {
            const response = await sendEmail();
            const form = document.getElementById('contact-form');

            if (response.status === 200) {
                setName('');
                setSurname('');
                setNumber('');
                setMail('');
                setHasCompany(false);
                setCompany('');
                setMessage('');

                form.reset();
                setIsUploading(false);
                showAlert('success', 'Fatto!');
            }
        } else {
            showAlert('error', 'Controlla la mail!');
            setIsUploading(false);
            return false;
        }


    }
    const [isUploading, setIsUploading] = useState(false);
    const [alertShow, setAlertShow] = useState(false);
    const [alertType, setAlertType] = useState();
    const [alertMessage, setAlertMessage] = useState();
    const [isAlertOpen, setIsAlertOpen] = useState(false);

    const handleAlertClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setIsAlertOpen(false);
        setAlertShow(false);
    };

    function SlideTransition(props) {
        return <Slide {...props} direction="down" />;
    }

    const showAlert = (type, message) => {
        setIsAlertOpen(true);
        setAlertType(type);
        setAlertMessage(message);
        setAlertShow(true);
    };


    const sendEmail = async () => {
        try {
            const response = await emailjs.send(
                process.env.REACT_APP_EMAILJS_SERVICEID,
                process.env.REACT_APP_EMAILJS_TEMPLATEID,
                {
                    to_email: process.env.REACT_APP_EMAILJS_TOEMAIL,
                    name: name,
                    surname: surname,
                    number: number ? number : 'Numero non indicato',
                    mail: mail,
                    hasCompany: hasCompany ? `Si, ${company}` : 'No',
                    message: message,
                },
                process.env.REACT_APP_EMAILJS_USERID
            );
            return response;
        } catch (error) {
            throw error;
        }
    };

    return (
        <>
            <Title title={title} />
            {(isUploading) && (<TruckLoader text="Invio dati in corso..." />)}
            <div className="contact-container">
                <h2 style={{ textAlign: "center" }}>Mettiti in contatto</h2>
                <ContactFormContainer id="formContainer">
                    <p>Non vediamo l'ora di lavorare con te!</p>
                    <Form id="contact-form">
                        <label htmlFor="name">Nome:</label>
                        <input type="text" id="name" name="name" required onChange={(e) => setName(e.target.value)} />

                        <label htmlFor="surname">Cognome:</label>
                        <input type="text" id="surname" name="surname" required onChange={(e) => setSurname(e.target.value)} />

                        <label htmlFor="number">Numero di telefono:</label>
                        <input type="number" id="number" name="number" onChange={(e) => setNumber(e.target.value)} />

                        <label htmlFor="email">Email:</label>
                        <input type="email" id="email" name="email" required onChange={(e) => setMail(e.target.value)} />

                        <label htmlFor="hasCompany">Possiedi un'azienda?</label>
                        <input type="checkbox" id="hasCompany" name="hasCompany" onChange={(e) => setHasCompany(e.target.checked)} />
                        {hasCompany &&
                            <>
                                <label htmlFor="company">Nome azienda</label>
                                <input
                                    type="text"
                                    id="company"
                                    name="company"
                                    required
                                    onChange={(e) => setCompany(e.target.value)}
                                />
                            </>}
                        <label htmlFor="message">Messaggio:</label>
                        <textarea id="message" name="message" rows="4" onChange={(e) => setMessage(e.target.value)} ></textarea>
                    </Form>
                    <StyledMuiButton variant="outlined" className="send-message" onClick={handleSubmit}>Invia</StyledMuiButton>
                </ContactFormContainer>
                {alertShow && (
                    <Snackbar style={{ zIndex: '9999', marginTop: '40px' }} open={isAlertOpen} autoHideDuration={3000} onClose={handleAlertClose} TransitionComponent={SlideTransition} anchorOrigin={{ vertical: 'top', horizontal: 'right' }} key={{ vertical: 'top', horizontal: 'right' }}>
                        <Alert
                            style={{ background: 'rgb(255 255 255 / 90%)' }}
                            onClose={handleAlertClose}
                            severity={alertType}
                            variant="outlined"
                            sx={{ width: '100%' }}
                        >
                            {alertMessage}
                        </Alert>
                    </Snackbar>)}
            </div>
        </>
    );
}