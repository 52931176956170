import React, { useEffect } from 'react';
import './TruckLoader.css'; // Crea un file CSS per il tuo componente

const TruckLoader = ({ text }) => {
      useEffect(() => {
            window.scrollTo(0, 0);
      }, []);
      return (
            <div className="truck-loader-container">
                  <div className="truck-loader">
                        <svg width="128" version="1.1" x="0px" y="0px" viewBox="0 0 512 512">
                              <circle style={{ fill: "#CCCCCC" }} cx="333.611" cy="368.787" r="50.063" />
                              <circle style={{ fill: "#CCCCCC" }} cx="116.763" cy="368.787" r="50.063" />
                              <g>
                                    <path d="M333.613,432.745c-35.265,0-63.955-28.689-63.955-63.955s28.689-63.955,63.955-63.955
      s63.955,28.689,63.955,63.955S368.878,432.745,333.613,432.745z M333.613,332.61c-19.949,0-36.18,16.23-36.18,36.18
      c0,19.95,16.231,36.18,36.18,36.18s36.18-16.23,36.18-36.18S353.563,332.61,333.613,332.61z"/>
                                    <path d="M116.766,432.745c-35.265,0-63.955-28.689-63.955-63.955s28.689-63.955,63.955-63.955
      s63.955,28.689,63.955,63.955S152.03,432.745,116.766,432.745z M116.766,332.61c-19.95,0-36.18,16.23-36.18,36.18
      c0,19.95,16.23,36.18,36.18,36.18s36.18-16.23,36.18-36.18S136.715,332.61,116.766,332.61z"/>
                                    <path d="M395.052,375.525h-9.111c-7.668,0-13.887-6.217-13.887-13.887s6.219-13.887,13.887-13.887h9.111
      c11.553,0,20.952-9.399,20.952-20.952v-14.072c0-7.67,6.219-13.887,13.887-13.887s13.887,6.217,13.887,13.887v14.072
      C443.778,353.667,421.918,375.525,395.052,375.525z"/>
                                    <path d="M67.215,375.525H37.856C16.983,375.525,0,358.543,0,337.669V279.23
      c0-18.462,10.231-35.116,26.699-43.463l32.863-16.655l32.46-105.833c0.025-0.082,0.051-0.164,0.078-0.246
      c6.549-20.021,25.102-33.515,46.168-33.578l256.773-0.201c26.877,0,48.737,21.858,48.737,48.726v46.568
      c0,7.67-6.219,13.887-13.887,13.887s-13.887-6.217-13.887-13.887v-46.568c0-11.553-9.399-20.952-20.952-20.952l-256.733,0.201
      c-8.991,0.026-16.938,5.78-19.786,14.329L84.384,232.902c-1.107,3.608-3.633,6.609-6.999,8.316l-38.13,19.325
      c-7.082,3.588-11.481,10.749-11.481,18.688v58.439c0,5.559,4.523,10.082,10.082,10.082h29.359c7.67,0,13.887,6.217,13.887,13.887
      C81.102,369.308,74.885,375.525,67.215,375.525z"/>
                                    <path d="M222.428,375.525h-56.112c-7.67,0-13.887-6.217-13.887-13.887s6.217-13.887,13.887-13.887h56.112
      c7.67,0,13.887,6.217,13.887,13.887C236.315,369.308,230.098,375.525,222.428,375.525z"/>
                                    <path d="M180.358,245.494H66.94c-7.67,0-13.887-6.217-13.887-13.887c0-7.67,6.217-13.887,13.887-13.887
      h113.418c7.67,0,13.887,6.217,13.887,13.887C194.245,239.277,188.028,245.494,180.358,245.494z"/>
                                    <path d="M214.155,196.392c-7.67,0-13.887-6.217-13.887-13.887V96.081c0-7.67,6.217-13.887,13.887-13.887
      c7.67,0,13.887,6.217,13.887,13.887v86.424C228.042,190.175,221.825,196.392,214.155,196.392z"/>
                              </g>
                              <path style={{ fill: "#CCCCCC" }} d="M429.051,208.771H314.447v-49.956h114.605c13.796,0,24.977,11.183,24.977,24.977l0,0
    C454.029,197.588,442.846,208.771,429.051,208.771z"/>

                              <path style={{ fill: "#000" }} d="M429.051,222.657H314.447c-7.668,0-13.887-6.217-13.887-13.887v-49.956
    c0-7.67,6.219-13.887,13.887-13.887h114.604c21.431,0,38.865,17.435,38.865,38.865S450.481,222.657,429.051,222.657z
    M328.334,194.883h100.717c6.114,0,11.09-4.976,11.09-11.09c0-6.115-4.976-11.09-11.09-11.09H328.334V194.883z"/>

                              <path style={{ fill: "#CCCCCC" }} d="M473.134,258.726H314.447V208.77h158.687c13.795,0,24.977,11.183,24.977,24.977l0,0
    C498.111,247.544,486.93,258.726,473.134,258.726z"/>

                              <path style={{ fill: "#000" }} d="M473.134,272.613H314.447c-7.668,0-13.887-6.217-13.887-13.887V208.77
    c0-7.67,6.219-13.887,13.887-13.887h158.689c21.431,0,38.865,17.435,38.865,38.865S494.565,272.613,473.134,272.613z
    M328.334,244.839h144.801c6.115,0,11.09-4.976,11.09-11.09c0-6.115-4.976-11.09-11.09-11.09H328.334V244.839z"/>
                        </svg>
                  </div>
                  <p>{text}</p>
            </div>
      );
};

export default TruckLoader;
