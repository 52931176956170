import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const FooterContainer = styled.footer`
  display: flex;
  flex-wrap: wrap;
  background-color: #444;
  font-size: .75rem;
  color: #d9d9d9;
  padding-top: 2rem;
  padding-bottom: 2rem;
  align-items: center;
  justify-content: center;
`;

const FooterColumn = styled.div`
  flex: 1;
  padding: 0 1rem;
  text-align: center;
`;
const SA = styled.a`
  text-decoration:none;
  color: inherit;
`;
const Footer = () => {
  return (
    <FooterContainer id="footer">
      <FooterColumn>&copy; 2024 T.R.L Express S.R.L. - Tutti i diritti riservati.</FooterColumn>
      <FooterColumn>Via Pietro Mascagni 12, Lainate - 20045</FooterColumn>
      <FooterColumn><SA href='mailto:trlexpresssrl@gmail.com'>trlexpresssrl@gmail.com</SA></FooterColumn>
      <FooterColumn><Link style={{ textDecoration: 'none', color: '#d9d9d9' }} to="/privacy-info">Informativa Privacy</Link></FooterColumn>
    </FooterContainer>
  );
};

export default Footer;