import React, { useState, useEffect } from "react";
import './IndexBody.css';
import { Button } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import capanno from "../../images/capanno.webp";
import capanno2 from "../../images/capanno2.webp";
import furgone from "../../images/furgone.webp";
import styled from "styled-components";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTruckFast, faWarehouse, faTruckRampBox, faCircleArrowDown } from '@fortawesome/free-solid-svg-icons';

const handleServClick = () => {
    const servicesContainer = document.getElementById('services-container');

    servicesContainer.scrollIntoView({
        behavior: 'smooth'
    });
}

const StyledMuiButton = styled(Button)`
    color: #fff !important;
    border-color: rgb(255 255 255) !important;
    background: rgb(0 0 0) !important;
    font-weight: bolder !important;
    font-family: "Montserrat", "Oxygen" !important;
`;


const images = [
    capanno,
    capanno2,
    furgone
];
const intMessages = [
    "Autotrasporti",
    "Spedizioni internazionali",
    "Magazzino e logistica"
];
const messages = [
    "Flessibilità, dinamicità, e affidabilità sono i nostri punti di forza per garantire le migliori prestazioni ai nostri clienti",
    "Servizi dedicati espressi, assicurando rapidità di consegna e cura nella movimentazione delle merci",
    "Disponiamo di un magazzino spazioso dove depositare la tua merce in maniera sicura e per tutto il tempo che ti occorre, occupandoci anche della logisitca"
];

const slideShowInterval = 4500;

export default function IndexBody() {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const [loaded, setLoaded] = useState(false);
    const [showText, setShowText] = useState(false);
    // const [scrollY, setScrollY] = useState(0);
    const [viewing, setViewing] = useState(1);
    const [fadeOut, setFadeOut] = useState(false);

    const navigate = useNavigate();
    useEffect(() => {
        const servicesBox = document.querySelectorAll('.service');

        servicesBox.forEach(serviceBox => {
            serviceBox.addEventListener('click', () => { navigate('/contact-us') })
        });

        const img = new Image();
        img.src = capanno;
        img.onload = () => {
            setLoaded(true);
        };

    }, []);

    useEffect(() => {
        const timeout = setTimeout(() => {
            setShowText(true);
        }, 100);

        setTimeout(() => {
            if (document.querySelector('.truck-slider')) {
                document.querySelector('.truck-slider').style.display = 'none';
            }
        }, 1500);
        return () => clearTimeout(timeout);
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            setFadeOut(true);
            setTimeout(() => {
                setViewing((prevViewing) => (prevViewing % messages.length) + 1);
                setFadeOut(false);
            }, 500);
        }, slideShowInterval);

        return () => clearInterval(interval);
    }, []);


    return (
        <>
            <div id="container">
                <div className={`top-part ${loaded ? 'loaded' : ''}`}>
                    <img className={`top-image ${fadeOut ? 'fade-out-img' : 'fade-in-img'}`} src={images[viewing - 1]} alt="Immagine auto carousel Home" />
                    <div className="top-part-text">
                        <div className={`top-text ${showText ? 'show' : ''}`}>
                            <div className={`truck-slider ${showText ? 'showing' : ''}`}>
                                <svg id="truck-svg" width="350" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M14 2.92V11.23C14 12.25 13.17 13.08 12.15 13.08H3C2.45 13.08 2 12.63 2 12.08V5.69C2 3.65 3.65 2 5.69 2H13.07C13.59 2 14 2.41 14 2.92Z" fill="#FFFFFF" />
                                    <path d="M21.5 15.5C21.78 15.5 22 15.72 22 16V17C22 18.66 20.66 20 19 20C19 18.35 17.65 17 16 17C14.35 17 13 18.35 13 20H11C11 18.35 9.65 17 8 17C6.35 17 5 18.35 5 20C3.34 20 2 18.66 2 17V15C2 14.45 2.45 14 3 14H12.5C13.88 14 15 12.88 15 11.5V6C15 5.45 15.45 5 16 5H16.84C17.56 5 18.22 5.39 18.58 6.01L19.22 7.13C19.31 7.29 19.19 7.5 19 7.5C17.62 7.5 16.5 8.62 16.5 10V13C16.5 14.38 17.62 15.5 19 15.5H21.5Z" fill="#FFFFFF" />
                                    <path d="M8 22C9.10457 22 10 21.1046 10 20C10 18.8954 9.10457 18 8 18C6.89543 18 6 18.8954 6 20C6 21.1046 6.89543 22 8 22Z" fill="#FFFFFF" />
                                    <path d="M16 22C17.1046 22 18 21.1046 18 20C18 18.8954 17.1046 18 16 18C14.8954 18 14 18.8954 14 20C14 21.1046 14.8954 22 16 22Z" fill="#FFFFFF" />
                                    <path d="M22 12.53V14H19C18.45 14 18 13.55 18 13V10C18 9.45 18.45 9 19 9H20.29L21.74 11.54C21.91 11.84 22 12.18 22 12.53Z" fill="#FFFFFF" />
                                </svg>
                            </div>
                            <h3>T.R.L. Express S.R.L.</h3>
                            <div>
                                <h6 style={{ margin: '1%' }} className={`message ${fadeOut ? 'fade-out' : 'fade-in'}`} viewid={viewing}>{intMessages[viewing - 1]}</h6>
                                <p className={`message ${fadeOut ? 'fade-out' : 'fade-in'}`} viewid={viewing}>{messages[viewing - 1]}</p>
                            </div>
                            <Link to="/contact-us"><StyledMuiButton name='contact-us' variant="outlined">Contattaci</StyledMuiButton></Link>
                            <br />
                            <p className="p-services">Visualizza i nostri servizi</p>
                            <FontAwesomeIcon icon={faCircleArrowDown} size="2xs" style={{ cursor: 'pointer' }} bounce onClick={handleServClick} />
                        </div>
                    </div>
                </div>
                <div id="services-container">
                    <h1>I nostri servizi</h1>
                    <div className="services">
                        <div className="service" title="Cliccami per un preventivo senza impegno!">
                            <FontAwesomeIcon icon={faTruckFast} size="2xl" style={{ color: '#161631' }} />
                            <h7>TRASPORTI DEDICATI NAZIONALI ED EUROPEI H24</h7>
                            <p>365 giorni l'anno, 7 giorni su 7, siamo a disposizione per organizzare, gestire ed effettuare trasporti e consegne urgenti. Ritiro e consegna al destinatario con tempistica programmata.</p>
                        </div>
                        <div className="service" title="Cliccami per un preventivo senza impegno!">
                            <FontAwesomeIcon icon={faWarehouse} size="2xl" style={{ color: '#161631' }} />
                            <h7>DEPOSITO MERCI</h7>
                            <p>Abbiamo a disposizione un magazzino di 500mq dotato di sistema di videosorveglianza dove è possibile stoccare le merci in totale sicurezza per brevi o lunghi periodi.</p>
                        </div>
                        <div className="service" title="Cliccami per un preventivo senza impegno!">
                            <FontAwesomeIcon icon={faTruckRampBox} size="2xl" style={{ color: '#161631' }} />
                            <h7>LOGISTICA DISTRIBUTIVA</h7>
                            <p>Oltre al deposito merci, offriamo servizio di distribuzione quotidiana in tutta la Lombardia.</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
