import React, {useEffect} from "react";
import { Button } from "@mui/material";
import FileInfo from '../../privacy/Informativa_TRL.pdf'
import Title from "../helpers/Title";

const handleDownload = () => {
    const link = document.createElement('a');
    link.href = FileInfo;
    link.target = '_blank';
    link.download = 'Informativa_TRL.pdf';

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};


export default function Privacy() {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

    return (
        <>
        <Title title="Informativa privacy" />
            <div id="privacy-container" style={{ padding: '5%' }}>
                <p><strong>INFORMATIVA PER CLIENTI AI SENSI DELL&rsquo;ART. 13 DEL REG. UE 2016/679</strong></p>
                <p>Nel ringraziarti per aver mostrato interesse per TRL Express S.R.L., desideriamo comunicarti che la corretta gestione dei tuoi Dati Personali &egrave; la nostra priorit&agrave;.</p>
                <p><strong>Essere informato in modo trasparente su come trattiamo e proteggiamo i tuoi Dati Personali, &egrave; un tuo diritto.</strong></p>
                <p>Ti preghiamo, quindi, di dedicare qualche minuto alla lettura di questa informativa (&ldquo;Informativa&rdquo;) resa ai sensi del D.Lgs. 30 giugno 2003 n. 196 (Codice Privacy), del Regolamento UE n. 679/2016 (&ldquo;GDPR&rdquo;) e successive norme nazionali di adeguamento (congiuntamente &ldquo;Normativa Applicabile&rdquo;), e che riguarda esclusivamente i dati personali raccolti presso tramite il sito web www.trlexpress.it (&ldquo;Sito&rdquo;).</p>
                <p>1.&nbsp; SOGGETTI DEL TRATTAMENTO:</p>
                <p>Il Titolare del trattamento &egrave; <strong>TRL EXPRESS S.R.L.</strong> con sede in via Newton, 12, 20016 Pero (MI).</p>
                <p>P.IVA: 10699900964</p>
                <p>E-MAIL: trlexpresssrl@gmail.com</p>
                <p>PEC: trlexpress@pec.it</p>
                <p>2.&nbsp; NATURA DEI DATI:</p>
                <p>Ti chiederemo e/o tratteremo le seguenti informazioni:</p>
                <p>&bull;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; dati anagrafici (per esempio: nome, cognome);</p>
                <p>&bull;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; recapiti (per esempio: numero di telefono e/o di cellulare, indirizzo mail);</p>
                <p>3.&nbsp; FINALIT&Agrave; DEL TRATTAMENTO E BASE GIURIDICA</p>
                <p><strong>Dar seguito ad una tua richiesta - eseguire un contratto.</strong></p>
                <p>Tratteremo i tuoi Dati Personali per rispondere alle tue richieste di informazioni, di preventivo o per sottoscrivere un contratto di acquisto e/o servizi.</p>
                <p>Per eseguire tali trattamenti non ti richiediamo un consenso espresso, poich&eacute; la base giuridica &egrave; l&rsquo;esecuzione del contratto o delle prestazioni/servizi da te richiesti. Al riguardo tieni presente che in tutti i casi che precedono sei libero di conferire o meno i tuoi Dati Personali, tuttavia se deciderai di non farlo, non sar&agrave; possibile sottoscrivere un contratto e/o dare seguito alle tue richieste di prestazioni e servizi.</p>
                <p>4.&nbsp; MODALIT&Agrave; DEL TRATTAMENTO DEI DATI</p>
                <p>Tratteremo i tuoi Dati Personali mediante strumenti manuali, informatici o telematici, ed in ogni caso idonei a garantirne la sicurezza e la riservatezza.</p>
                <p>Il trattamento verr&agrave; eseguito da personale che abbiamo debitamente istruito e formato nonch&eacute; contrattualizzato.</p>
                <p>Potrai essere contattato via e-mail, sms, o attraverso altri strumenti elettronici equivalenti o chiamata a tutti i recapiti che hai fornito.</p>
                <p>5.&nbsp; DIFFUSIONE DEI DATI E TRASFERIMENTO DEI DATI ALL&rsquo;ESTERO</p>
                <p>I suoi dati personali non sensibili potranno essere resi accessibili ad altri soggetti (a titolo indicativo: istituti di credito, studi professionali, consulenti fiscali, studi legali, consulenti del lavoro, consulenti informatici, ecc.) che svolgono attivit&agrave; in outsourcing per conto del Titolare, nella loro qualit&agrave; di responsabili esterni del trattamento. L&rsquo;elenco completo ed aggiornato dei destinatari dei dati personali &egrave; disponibile presso il Titolare del trattamento.</p>
                <p>I suoi dati personali possono essere comunicati a: soggetti, enti o autorit&agrave; a cui sia obbligatorio comunicare i suoi dati personali in forza di disposizioni di legge, di regolamento o di ordini delle autorit&agrave;.</p>
                <p>In caso di trasferimenti internazionali dei dati a partire dallo Spazio Economico Europeo (EEA), laddove la Commissione europea abbia riconosciuto che un Paese non appartenente alla EEA &egrave; in grado di garantire un adeguato livello di protezione dei dati, i dati dell&rsquo;interessato potranno essere trasferiti su tale base.</p>
                <p>6.&nbsp; PERIODO DI CONSERVAZIONE DEI DATI</p>
                <p>Conserviamo i tuoi Dati Personali per il tempo strettamente necessario a dar seguito alle tue richieste e/o perseguire le finalit&agrave; che ti abbiamo illustrato sopra. Di seguito trovi una tabella riassuntiva per le finalit&agrave; principali:</p>
                <p>Dar seguito ad una tua richiesta - esecuzione di un contratto</p>
                <p>Per tutta la durata del rapporto e successivamente per 10 anni (prescrizione ordinaria).</p>
                <p>Dar seguito ad una tua richiesta, qualora non ancora cliente</p>
                <p>2 mesi dalla raccolta.</p>
                <p>7.&nbsp; DIRITTI DELL&rsquo;INTERESSATO</p>
                <p>In quanto interessato ha il diritto di ottenere, nei casi previsti, l&apos;accesso ai dati personali e la rettifica o la cancellazione degli stessi o la limitazione del trattamento che li riguarda o di opporsi (artt. 15 e ss. del GDPR). In particolare, a mero titolo esemplificativo, l&rsquo;interessato potr&agrave; esercitare i seguenti diritti:</p>
                <p><strong>a)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</strong> <strong>Diritto di accesso ai dati (art. 15):</strong> l&apos;interessato ha il diritto di ottenere dal titolare del trattamento la conferma che sia o meno in corso un trattamento di dati personali che lo riguarda e, in tal caso, di ottenere l&apos;accesso ai dati personali in un formato elettronico di uso comune ed alcune informazioni sul trattamento (es. finalit&agrave;, categorie di dati trattati, destinatari a cui sono comunicati, ecc.);</p>
                <p><strong>b)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</strong> <strong>Diritto di rettifica dei dati (art. 16):</strong> l&rsquo;interessato ha il diritto di rettificare i suoi dati personali nel caso in cui non siano corretti, incluso il diritto di richiedere il completamento di dati personali non completi;</p>
                <p><strong>c)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</strong> <strong>Diritto alla cancellazione dei dati o &ldquo;diritto all&rsquo;oblio&rdquo;:</strong> l&rsquo;interessato ha il diritto di ottenere dal titolare del trattamento la cancellazione dei dati personali che lo riguardano e il titolare del trattamento ha l&rsquo;obbligo di cancellare senza ingiustificato ritardo i dati personali;</p>
                <p><strong>d)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</strong> <strong>Diritto alla limitazione del trattamento:</strong> l&rsquo;interessato ha il diritto di ottenere dal titolare del trattamento la limitazione del trattamento dei propri dati personali qualora ricorrano le ipotesi di legge;</p>
                <p><strong>e)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</strong> <strong>Diritto alla portabilit&agrave; dei dati:</strong> l&rsquo;interessato ha il diritto di ricevere in un formato strutturato, di uso comune e leggibile da dispositivo automatico i dati personali che lo riguardano forniti a un titolare del trattamento e ha il diritto di trasmettere tali dati a un altro titolare del trattamento senza impedimenti da parte del titolare del trattamento cui li ha forniti;</p>
                <p><strong>f)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</strong> <strong>Diritto di opposizione al trattamento:</strong> l&rsquo;interessato ha il diritto di opporsi in qualsiasi momento al trattamento dei dati personali che lo riguardano per motivi connessi alla sua situazione particolare;</p>
                <p><strong>g)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</strong> <strong>Diritto di proporre reclamo all&rsquo;Autorit&agrave; di controllo:</strong> fatto salvo ogni altro ricorso amministrativo o giurisdizionale, l&rsquo;interessato ha il diritto di proporre reclamo all&rsquo;Autorit&agrave; Garante per la protezione dei dati personali nel caso in cui ritenesse che i trattamenti di dati effettuati violino la vigente normativa in materia di protezione dei dati personali.</p>
                <p>L&rsquo;istanza per l&rsquo;esercizio dei diritti (artt. 15/22 del GDPR) potr&agrave; essere inoltrata dagli interessati al seguente indirizzo e-mail: trlexpresssrl@gmail.com</p>
            </div>
            <div style={{ display: 'grid', justifyContent: 'center', paddingBottom: '1%' }}>
                <Button name='download-informativa' style={{ color: '#fff', borderColor: '#000', background: 'rgb(0 0 0/ 80%)', fontWeight: 'bolder', fontFamily: '"Montserrat", "Oxygen"' }} onClick={handleDownload} variant="outlined">Scarica il documento</Button>
            </div>
        </>
    );
}